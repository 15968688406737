import classNames from 'classnames';
import { Modal } from 'components/Modal';
import { ModalBody } from 'components/Modal/ModalBody';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Icons } from 'icons';
import styles from '../Map.module.scss';
import { Input } from 'elements/Input';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { useState } from 'react';

export const FutureLeadForm = ({ futureLeadData, onClose, onChange }: {
    futureLeadData?: {
        postcode: '',
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        supplier: '',
        endDate: '',
        cost: '',
    },
    onClose: any,
    onChange: any,
}) => {
    const [data, setData] = useState(futureLeadData || {
        postcode: '',
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        supplier: '',
        endDate: '',
        cost: '',
    });

    const handle = () => {
        onChange(data);
    };

    return (
        <Modal className={styles.modal} onClose={() => onClose()}>
            <ModalHeader>
                <div className={styles.decoration}>
                    <Icons.Edit />
                </div>
                <div className={classNames(styles.textBlock)}>
                    <h2 className={classNames('display-sm', 'text-600', 'text-primary')}>Future lead</h2>
                </div>

            </ModalHeader>
            <ModalBody className={styles.gridColumn}>
                <Input label='Postcode' placeholder='Postcode'
                    value={data.postcode} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            postcode: e.target.value
                        }));
                    }} />
                <Input label='First Name' placeholder='First Name'
                    value={data.firstName} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            firstName: e.target.value
                        }));
                    }} />
                <Input label='Last Name' placeholder='Last Name'
                    value={data.lastName} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            lastName: e.target.value
                        }));
                    }} />
                <Input label='Telephone' placeholder='Telephone'
                    value={data.telephone} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            telephone: e.target.value
                        }));
                    }} />
                <Input label='Email' placeholder='Email'
                    value={data.email} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            email: e.target.value
                        }));
                    }} />
                <Input label='Current Supplier' placeholder='Current Supplier'
                    value={data.supplier} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            supplier: e.target.value
                        }));
                    }} />
                <Input label='Contact end Date' placeholder='Contact end Date'
                    value={data.endDate} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            endDate: e.target.value
                        }));
                    }} />
                <Input label='Cost' placeholder='Cost'
                    value={data.cost} onChange={(e: any) => {
                        setData(dat => ({
                            ...dat,
                            cost: e.target.value
                        }));
                    }} />

            </ModalBody>
            <ModalFooter >

                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Discard
                </Button>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.PRIMARY}
                    onClick={() => {
                        setData({
                            postcode: '',
                            firstName: '',
                            lastName: '',
                            telephone: '',
                            email: '',
                            supplier: '',
                            endDate: '',
                            cost: '',
                        });
                        handle();
                        onClose();

                    }}
                >
                    Save
                </Button>
            </ModalFooter >
        </Modal>
    );
};