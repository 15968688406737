import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { BodyPage } from 'elements/BodyPage';
import { Input } from 'elements/Input';
import { Skeleton } from 'elements/Skeleton';
import { User } from 'elements/User';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Bounce, toast } from 'react-toastify';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { UpdateUserInputs, defaultValues, validationSchema } from './FormValidation';
import styles from './styles.module.scss';

export const SettingsPage = () => {
    const { data: currentUser, refetch, isLoading: getCurrentUserIsLoading } = usersAPI.useGetCurrentUserQuery();
    const [isChangePass, setChangePass] = useState(false);

    const [updateUser, { error: updateUserError, isSuccess, isError }] = usersAPI.useUpdateUserMutation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        reset
    } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: typeof defaultValues) => {
        try {
            await updateUser({
                user_uuid: currentUser?.uuid || '',
                body: {
                    password: data.password,
                }
            });
            // onClose();
            refetch();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Updating creating user:', error, updateUserError);
        }

    };

    watch(UpdateUserInputs.PASSWORD);
    watch(UpdateUserInputs.PASSWORD_CONFIRM);

    useEffect(() => {
        // @ts-ignore
        if (isSuccess) {
            toast.success('Password has been successfully updated!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        // @ts-ignore
        if (isError) {
            toast.error('An error occurred while updating user. Please ensure all required parameters and configurations are set correctly. If the issue persists, contact support for assistance.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        }
    }, [isError]);

    return <>
        {getCurrentUserIsLoading && <Skeleton />}
        {!getCurrentUserIsLoading && <User name={currentUser?.username}
            className={styles.userBadge}
            isNameShow={true}
        />}
        <BodyPage>
            <div className={styles.pageGrid}>
                <div className={styles.field}>
                    <div className={styles.fieldTextBlock}>
                        <p className={classNames('text-sm', 'text-500', 'text-primary')}>Name</p>

                    </div>
                    <Input value={currentUser?.username} disabled />
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldTextBlock}>
                        <p className={classNames('text-sm', 'text-500', 'text-primary')}>Email Address</p>

                    </div>
                    <Input value={currentUser?.email} disabled />
                </div>

                <div className={styles.field}>
                    <div className={styles.fieldTextBlock}>
                        <p className={classNames('text-sm', 'text-500', 'text-primary')}>Password</p>
                        <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Please enter your current password to change your password.</p>

                    </div>
                    <div className={styles.fieldPassword}>
                        {!isChangePass && <Button size={ButtonSize.MD}
                            palette={ButtonPalette.SECONDARY_GRAY}
                            onClick={() => setChangePass(true)}>Change password</Button>}

                        {isChangePass && <>
                            <Input.Password
                                label='New Password'
                                placeholder='Password'
                                error={{
                                    message: errors.password?.message
                                }}
                                registerProps={
                                    { ...register(UpdateUserInputs.PASSWORD, { required: false }) }
                                } />
                            <Input.Password label='Confirm New Password'
                                placeholder='Password confirmation'
                                error={{
                                    message: errors.password_confirm?.message
                                }}
                                registerProps={
                                    { ...register(UpdateUserInputs.PASSWORD_CONFIRM, { required: true }) }
                                } />
                        </>}

                    </div>
                    {isChangePass && <div className={styles.footer}>
                        <Button size={ButtonSize.MD}
                            palette={ButtonPalette.SECONDARY_GRAY}
                            onClick={() => {
                                setChangePass(false);
                                reset();
                            }}>Cancel</Button>
                        <Button size={ButtonSize.MD}
                            palette={ButtonPalette.PRIMARY}
                            onClick={handleSubmit(onSubmit)}>Update password</Button>
                    </div>
                    }
                </div>
            </div>
        </BodyPage>

    </>;
};;