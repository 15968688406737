import { OutcomeStatus } from 'app/types/OutcomeStatus';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Select } from 'elements/Select';
import { Skeleton } from 'elements/Skeleton';
import { Icons } from 'icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { addAddressUprn, selectAddress } from 'store/reducers/addressSlice';
import { addAllAddresses, selectCampaign } from 'store/reducers/campaignSlice';
import { selectRun } from 'store/reducers/runSlice';
import { InfoAboutAddress } from './InfoAboutAddress';
import styles from './Map.module.scss';

export const getIndicatorByOutcome = (outcome: string) => {

    const obj = {
        title: '',
        indicator: '',
        outcome: OutcomeStatus.CHURN
    };

    switch (outcome) {
        case OutcomeStatus.NO_SALE:
        case OutcomeStatus.NO_SALE.toLowerCase():
            obj.title = 'No sale';
            obj.indicator = '🟥';
            obj.outcome = OutcomeStatus.NO_SALE;
            break;

        case OutcomeStatus.SALE:
        case OutcomeStatus.SALE.toLowerCase():
            obj.title = 'Sale';
            obj.indicator = '🟩';
            obj.outcome = OutcomeStatus.SALE;
            break;

        case OutcomeStatus.NO_ANSWER:
        case OutcomeStatus.NO_ANSWER.toLowerCase():
            obj.title = 'No answer';
            obj.indicator = '🟨';
            obj.outcome = OutcomeStatus.NO_ANSWER;
            break;

        case OutcomeStatus.CHURN:
            obj.title = 'To be knocked';
            obj.indicator = '🟨';
            obj.outcome = OutcomeStatus.CHURN;
            break;
    }

    return obj;
};

export const AddressesSelector = () => {
    const dispatch = useDispatch();
    const { uuid: storageUuid } = useSelector(selectRun);
    const { uprn } = useSelector(selectAddress);
    const { addresses = [] } = useSelector(selectCampaign);

    const [nextValue, setNextValue] = useState<number>();

    const { data: properties = [], isLoading: isPropertiesLoading, } = campaignRunAPI.useGetAllPropertiesInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const { data: outcomes = [], isLoading: isOutcomesLoading, refetch, } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    useEffect(() => {
        if (addresses.length) return;

        if (properties.length) {
            dispatch(addAllAddresses(properties));
        }
    }, [isPropertiesLoading]);

    const options: {
        label: any,
        title: OutcomeStatus,
        options: {
            value: number;
            label: string;
        }[];
    }[] = [
            {
                label: <span>To be knocked</span>,
                title: OutcomeStatus.CHURN,
                options: [],
            },
            {
                label: <span>No Answer</span>,
                title: OutcomeStatus.NO_ANSWER,
                options: []
            },
            {
                label: <span>No Sale</span>,
                title: OutcomeStatus.NO_SALE,
                options: []
            },
            {
                label: <span>Sale</span>,
                title: OutcomeStatus.SALE,
                options: []
            },
        ];

    // think about
    addresses.forEach((el: any) => {
        const outcomeValue = outcomes.find((item) => item.uprn === el.uprn);
        const indicator = getIndicatorByOutcome(outcomeValue?.outcome || OutcomeStatus.CHURN).indicator;

        const obj = {
            value: el.uprn,
            label: `${el.road} ${el.building_number || el.building_name}, ${el.town}, ${el.postcode} ${indicator}`
        };

        if (outcomeValue?.outcome && outcomeValue?.outcome === OutcomeStatus.SALE) {
            options[3].options.push(obj);
        }
        if (outcomeValue?.outcome && outcomeValue?.outcome === OutcomeStatus.NO_SALE) {
            options[2].options.push(obj);
        }
        if (outcomeValue?.outcome && outcomeValue?.outcome === OutcomeStatus.NO_ANSWER) {
            options[1].options.push(obj);
        }
        if (!outcomeValue?.outcome || outcomeValue?.outcome === OutcomeStatus.CHURN) {
            options[0].options.push(obj);
        }
    });

    useEffect(() => {
        if (uprn) {
            refetch();
        }
    }, [uprn]);

    useEffect(() => {
        if (nextValue) {
            dispatch(addAddressUprn(nextValue));
        }
    }, [nextValue]);

    useEffect(() => {
        if (addresses.length && options[0]?.options?.length && !uprn) {
            setNextValue(options[0]?.options[0]?.value);
        }
    }, [options, addresses, uprn]);

    return <div className={styles.addressSelect}>
        {isPropertiesLoading && <Skeleton height={80} />}
        {
            !isPropertiesLoading && !isOutcomesLoading && <Select
                width={{
                    width: '100%',
                    maxWidth: '100%'
                }}
                isSearch={false}
                isBlock
                value={nextValue}
                label='Address'
                data={options}
                onChange={(value) => {
                    dispatch(addAddressUprn(value));
                    setNextValue(value);

                }}
            />
        }

        <div className={styles.area}>
            <InfoAboutAddress />

            <div className={styles.btnBlock}>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => {

                        const item = addresses.findIndex((el: any) => el.uprn === nextValue) - 1;
                        if (item >= 0) {
                            setNextValue(addresses[item].uprn);
                        }
                    }}
                    isDisabled={
                        addresses.findIndex((el: any) => el.uprn === nextValue) - 1 <= 0
                    }
                >
                    <Icons.ArrowLeft />
                </Button>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => {
                        const item = addresses.findIndex((el: any) => el.uprn === nextValue) + 1;
                        if (item < addresses.length) {
                            setNextValue(addresses[item].uprn);

                        }

                    }}
                    isDisabled={
                        addresses.findIndex((el: any) => el.uprn === nextValue) + 1 >= addresses.length
                    }
                >
                    <Icons.ArrowRight />
                </Button>
            </div>
        </div >

    </div>;
};