import { Navigation } from 'components/Navigation';
import { ROUTES } from 'constants/routes';
import { MobileLayout } from 'elements/MobileLayout';
import { SettingsLayout } from 'elements/SettingsLayout';
import { LoginPage } from 'pages/LoginPage';
import { Main } from 'pages/Main';
import { MapPage } from 'pages/MapPage';
import { NotFound } from 'pages/NotFound';
import { SettingsPage } from 'pages/Settings';
import { Route, Routes } from 'react-router-dom';

export const RoutesComponent = () => {
    return (
        <>
            <Routes>
                <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
                <Route path={ROUTES.ROOT} element={<MobileLayout />}>
                    <Route index element={<Main />} />
                    <Route path={`${ROUTES.ROOT}/:uuid`} element={<MapPage />} />

                </Route>
                <Route path={ROUTES.USER_MENU} element={<SettingsLayout />}>
                    <Route index element={<Navigation />} />

                    <Route path={`${ROUTES.USER_MENU}/${ROUTES.SETTINGS}`} element={<SettingsPage />} />
                </Route>
                <Route path='*' element={<NotFound />} />

            </Routes>
        </>
    );
};;
