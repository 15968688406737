import { createSlice } from '@reduxjs/toolkit';

const regionSlice = createSlice({
    name: 'region',
    initialState: {
        uuid: null
    },
    reducers: {
        addRegionUuid: (state, action) => {
            state.uuid = action.payload;
        },
        removeRegionUuid: (state) => {
            state.uuid = null;
        },
    }
});

export const selectRegion = (state: any) => state.region;

export const {
    addRegionUuid,
} = regionSlice.actions;

export default regionSlice.reducer;
