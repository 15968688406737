export const selectToken = {
    token: {
        colorBgElevated: 'var(--bg-primary)',
        colorBgContainer: 'var(--bg-primary)',
        colorBorder: 'var(--border-primary)',
        colorText: 'var(--text-primary)',
        colorTextPlaceholder: 'var(--text-placeholder)',
        fontSize: 14,

        controlOutline: 'var(--border-brand)',
        controlOutlineWidth: 1,
        controlPaddingHorizontal: 14,
        controlHeight: 44,
        clearBg: 'transparent',
        borderRadius: 8,
    },
    components: {
        Select: {
            multipleItemBg: 'rgba(255, 0, 0, 0)',
            multipleItemBorderColor: '#f00',
            multipleItemColorDisabled: '#f0f',
        },
    },

};