import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Icons } from 'icons';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

export const SettingsLayout = () => {
    const navigate = useNavigate();

    return (
        <main className={styles.pageLayout}>
            <Button onClick={() => navigate(-1)} size={ButtonSize.MD} palette={ButtonPalette.TERTIARY_GRAY}><Icons.ArrowLeft /></Button>

            <Outlet />
        </main>
    );
};