import * as yup from 'yup';

export enum UpdateUserInputs {
    PASSWORD = 'password',
    PASSWORD_CONFIRM = 'password_confirm'
}

export const validationSchema = yup
    .object({
        password: yup.string().required('Password is required'),
        password_confirm: yup.string()
            .oneOf([yup.ref('password')], 'Passwords must match').required('Password is required')
    })
    .required();

export const defaultValues = {
    password: '',
    password_confirm: ''
};