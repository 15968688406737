import { OutcomeStatus } from 'app/types/OutcomeStatus';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Skeleton } from 'elements/Skeleton';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { selectAddress } from 'store/reducers/addressSlice';
import { selectRun } from 'store/reducers/runSlice';
import styles from './Map.module.scss';
import { ModalEdit, ModalNoSale, ModalSale } from './Modals';

export const Actions = () => {
    const { uuid: storageUuid } = useSelector(selectRun);
    const { uprn } = useSelector(selectAddress);

    const [isSale, setSale] = useState(false);
    const [isNoSale, setNoSale] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const { data: outcomes = [], isLoading: isOutcomesLoading, refetch } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const currentStatus = outcomes.find((el) => el.uprn === uprn);

    if (isOutcomesLoading) return <Skeleton height={80} />;

    return <>
        <div className={classNames(styles.btnLayout)}>
            {
                (!currentStatus || currentStatus?.outcome === OutcomeStatus.CHURN) && <div className={styles.btnGrid}>
                    <Button
                        size={ButtonSize.XL}
                        palette={ButtonPalette.PRIMARY}
                        onClick={() => {
                            setSale(true);
                        }}
                        isDisabled={!uprn}
                    >
                        Sale
                    </Button>
                    <Button
                        size={ButtonSize.XL}
                        palette={ButtonPalette.SECONDARY_GRAY}
                        onClick={() => {
                            setNoSale(true);
                        }}
                        isDisabled={!uprn}>
                        No sale
                    </Button>
                </div>
            }

            {
                (currentStatus?.outcome !== OutcomeStatus.CHURN && currentStatus) && <div className={classNames(styles.btnGrid, styles.oneColumn)}>
                    <Button
                        size={ButtonSize.XL}
                        palette={ButtonPalette.SECONDARY_GRAY}
                        onClick={() => {
                            setEdit(true);
                        }}
                        isDisabled={!uprn}>
                        Edit record
                    </Button>
                </div>
            }
        </div >

        {
            isSale && <ModalSale
                onClose={() => setSale(false)}
                refetch={refetch}
            />
        }
        {
            isNoSale && <ModalNoSale
                onClose={() => setNoSale(false)}
                refetch={refetch}
            />
        }
        {
            isEdit && <ModalEdit
                onClose={() => setEdit(false)}
                refetch={refetch}
            />
        }
    </>;
};