import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
    name?: string;
    email?: string;
    isNameShow?: boolean;
    className?: string;
}
export const User: React.FC<Props> = ({ name = '', email, isNameShow = true, className }) => {
    return <div className={classNames(styles.userBadge, className)}>
        <div className={styles.userAvatar}>{name[0]}</div>
        {isNameShow && <div className={styles.userInfo}>
            {name && <p className={classNames('text-secondary', 'text-sm', 'text-500')}>{name}</p>}
            {email && <p className={classNames('text-tertiary', 'text-xs', 'text-400')}>{email}</p>}
        </div>}
    </div>;
};