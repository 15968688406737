import classNames from 'classnames';
import style from './style.module.scss';
import React from 'react';
interface Props {
    children: any;
    className?: string;
}

export const BodyPage: React.FC<Props> = ({ children, className }) => {
    return (<>
        <div className={classNames(style.body, className)}>
            {children}
        </div>
    </>);
};