import { createSlice } from '@reduxjs/toolkit';

const initialState: {
    uuid: '',
    addresses: any;
} = {
    uuid: '',
    addresses: []
};
const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        addCampaignUuid: (state, action) => {
            state.uuid = action.payload.uuid;
        },
        removeCampaignUuid: (state, action) => {
            state.uuid = action.payload.uuid;
        },
        addAllAddresses: (state, action) => {
            state.addresses.push(...action.payload);
        },
        removeAddresses: (state) => {
            state.addresses = [];
        },
    }
});

export const selectCampaign = (state: any) => state.campaign;

export const {
    addCampaignUuid,
    addAllAddresses,
    removeCampaignUuid,
    removeAddresses
} = campaignSlice.actions;

export default campaignSlice.reducer;
