import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Skeleton } from 'elements/Skeleton';
import styles from '../Table.module.scss';

export const TableBody = ({ data, config, rowClickHandler, selectedCount }: any) => {
    const rowsJSX = data.map((rowData: any) => {
        const renderedCells = config.map((configItem: any) => {
            return (
                <td
                    onClick={rowClickHandler ? () => rowClickHandler(rowData.uuid) : () => undefined}
                    className={classNames('text-sm', 'text-400', 'text-tertiary', styles.td)}
                    key={configItem.label + rowData.id}
                >
                    <div>{configItem.render(rowData as any)}</div>
                </td>
            );
        });

        return <tr key={rowData.id}>{renderedCells}</tr>;
    });

    const skeletonTable = [...new Array(selectedCount)].map(() => {
        const emptyCellSkeleton = config.map(() => {
            return (
                <td key={nanoid()} className={classNames(styles.cellPadding)}>
                    <Skeleton.TableCell />
                </td>
            );
        });

        return <tr key={nanoid()}>{emptyCellSkeleton}</tr>;
    });

    return (<>
        <tbody>
            {data.length === 0 ? skeletonTable : rowsJSX}
        </tbody>
    </>);
};