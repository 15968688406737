import { ROUTES } from 'constants/routes';
import { User } from 'elements/User';
import { useAuth } from 'hooks/useAuth';
import { Icons } from 'icons';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import styles from './style.module.scss';

export const Header = () => {
    const { setToken } = useAuth();

    const { data: currentUser, isError } = usersAPI.useGetCurrentUserQuery();

    useEffect(() => {
        // @ts-ignore
        if (isError) {
            toast.error('An error occurred.', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });

            setToken('');
        }
    }, [isError]);

    return (
        <header className={styles.header}>
            <Link to='/'>
                <div className={styles.logo}>
                    <Icons.HermesLogo />
                    Hermes
                </div>
            </Link>
            <Link to={ROUTES.USER_MENU}>
                <User name={currentUser?.username} isNameShow={false} />
            </Link>
        </header>
    );
};