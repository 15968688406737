import { OutcomeStatus } from 'app/types/OutcomeStatus';
import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Modal } from 'components/Modal';
import { ModalFooter } from 'components/Modal/ModalFooter';
import { ModalHeader } from 'components/Modal/ModalHeader';
import { Icons } from 'icons';
import { useDispatch, useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { addAddressOutcomeStatus, selectAddress } from 'store/reducers/addressSlice';
import { selectRun } from 'store/reducers/runSlice';
import styles from '../Map.module.scss';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';

export const ModalSale = ({ onClose, refetch }: any) => {
    const dispatch = useDispatch();
    const { uuid: storageUuid } = useSelector(selectRun);
    const { uprn } = useSelector(selectAddress);
    const { data: currentUser = {} }: any = usersAPI.useGetCurrentUserQuery();

    const { data: outcomes = [] } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const [createOutcome] = campaignRunAPI.useCreateOutcomeForPropertyMutation();
    const [updateOutcome] = campaignRunAPI.useUpdateOutcomeForPropertyMutation();

    const currentStatus = outcomes.find((el) => el.uprn === uprn);
    const [additionalNotes, setAdditionalNotes] = useState<string>(currentStatus?.extra_info?.notes);

    const handleClick = async () => {
        try {
            if (!currentStatus) {
                await createOutcome({
                    run_uuid: storageUuid,
                    body: {
                        run_uuid: storageUuid,
                        outcome: OutcomeStatus.SALE,
                        outcome_at: new Date().toISOString(),
                        uprn,
                        worker_uuid: currentUser.uuid,
                        extra_info: { reason: OutcomeStatus.SALE, notes: additionalNotes }

                    }
                });
                refetch();
            } else {
                await updateOutcome({
                    outcome_uuid: currentStatus.uuid,
                    run_uuid: storageUuid,
                    body: {
                        outcome: OutcomeStatus.SALE,
                        outcome_at: new Date().toISOString(),
                        worker_uuid: currentUser.uuid,
                        extra_info: { reason: OutcomeStatus.SALE, notes: additionalNotes }
                    }
                });
                refetch();
            }
            dispatch(addAddressOutcomeStatus({
                outcome: OutcomeStatus.SALE
            }));
        } catch (error) {
            // @ts-ignore
            console.error(error);
        }
    };
    const onNotesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAdditionalNotes(e.target.value);
    };
    return (
        <Modal className={styles.modal} onClose={() => onClose()}>
            <ModalHeader>
                <div className={styles.decoration}>
                    <Icons.SuccessIcon />
                </div>
                <div className={classNames(styles.textBlock)}>
                    <h2 className={classNames('display-sm', 'text-600', 'text-primary')}>Sale</h2>
                    <p className={classNames('text-sm', 'text-400', 'text-tertiary')}>Successful sale will be recorded</p>
                </div>
                <TextArea rows={4} onChange={onNotesChange} value={additionalNotes} placeholder='Additional notes' />

            </ModalHeader>
            <ModalFooter>

                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.SECONDARY_GRAY}
                    onClick={() => {
                        onClose();
                    }}
                >
                    Discard
                </Button>
                <Button size={ButtonSize.LG}
                    palette={ButtonPalette.PRIMARY}
                    onClick={() => {
                        handleClick();
                        onClose();
                    }}>
                    Save
                </Button>
            </ModalFooter>
        </Modal >
    );
};