import { createSlice } from '@reduxjs/toolkit';

const hermesSlice = createSlice({
    name: 'hermes',
    initialState: {
        from_date: null,
        to_date: null,
        polygon_id: null,
        polygon_name: null
    },
    reducers: {
        addFromDate: (state, action) => {
            state.from_date = action.payload;
        },

        addToDate: (state, action) => {
            state.to_date = action.payload;
        },

        addPolygonId: (state, action) => {
            state.polygon_id = action.payload;
        },

        addPolygonName: (state, action) => {
            state.polygon_name = action.payload;
        },
    }
});

export const selectHermes = (state: any) => state.hermes;

export const {
    addFromDate,
    addToDate,
    addPolygonId,
    addPolygonName
} = hermesSlice.actions;

export default hermesSlice.reducer;
