import { ToastContainer } from 'react-toastify';
import { RoutesComponent } from './Routes';
import AuthWrapper from './providers/AuthWrapper';

import 'react-toastify/dist/ReactToastify.css';
function App() {
    return (
        <>
            <AuthWrapper>
                {RoutesComponent()}
                <ToastContainer newestOnTop />
            </AuthWrapper>
        </>
    );
}

export default App;
