import { createApi } from '@reduxjs/toolkit/query/react';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

interface OutcomeExport {
    extra_info: any;
    created_at: string;
    updated_at: string;
    uuid: string;
    run_uuid: string;
    campaign_uuid: string;
    worker_uuid: string;
    sales_person: string;
    uprn: number;
    outcome: string;
    outcome_at: string;
    created_by_user_uuid: string;
    updated_by_user_uuid: string;
    filename: string;
}

interface PropertiesExport {
    created_at: string;
    updated_at: string;
    uuid: string;
    run_uuid: string;
    worker_uuid: string;
    uprn: number;
    chunk_id: number;
    order: number;
    predicated_pen: number;
    geometry: any,
    classification_code: string;
    sub_building_name: string;
    building_name: string;
    building_number: number;
    road: string;
    town: string;
    postcode: string;
    pen_model_type: string;
}

enum CampaignRunStatus {
    PENDING = 'PENDING',
    RUNNING = 'RUNNING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}
interface CampaignRun {
    extra_info: any;
    created_at: string;
    updated_at: string;
    uuid: string;
    campaign_uuid: string;
    pen_model_uuid: string;
    name: string;
    is_active: true,
    chunk_count: number;
    min_property_count: number;
    geometry: any;
    geometry_native_srid: number;
    exchange_area_id: number;
    status: CampaignRunStatus,
    status_description: string;
}

export const campaignRunAPI = createApi({
    reducerPath: 'campaignRunAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getAllCampaignRuns: build.query<CampaignRun[], void>({
            query: () => ({
                url: '/campaign-runs',
                method: 'GET',
            }),
        }),
        createCampaignRun: build.mutation<any, any>({
            query: (body) => ({
                url: '/campaign-runs/',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        getAllPropertiesInRun: build.query<PropertiesExport[], { run_uuid: string; }>({
            query: ({ run_uuid }) => ({
                url: `/campaign-runs/${run_uuid}/properties`,
                method: 'GET',
            }),
            keepUnusedDataFor: 5,
        }),
        getAllOutcomeInRun: build.query<OutcomeExport[], { run_uuid: string; }>({
            query: ({ run_uuid }) => ({
                url: `/campaign-runs/${run_uuid}/outcomes`,
                method: 'GET',
            }),
        }),
        updateOutcomeForProperty: build.mutation<{
            extra_info: any,
            created_at: string;
            updated_at: string;
            uuid: string;
            run_uuid: string;
            campaign_uuid: string;
            worker_uuid: string;
            sales_person: string;
            uprn: 0,
            outcome: string;
            outcome_at: string;
            created_by_user_uuid: string;
            updated_by_user_uuid: string;
            filename: string;
        }[], {
            outcome_uuid: string; run_uuid: string; body: {
                outcome: string;
                outcome_at: string;
                worker_uuid: string;
                extra_info?: any;

            };
        }>({
            query: ({ run_uuid, outcome_uuid, body }) => ({
                url: `/campaign-runs/${run_uuid}/outcomes/${outcome_uuid}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Create']
        }),

        createOutcomeForProperty: build.mutation<{
            extra_info: any,
            created_at: string;
            updated_at: string;
            uuid: string;
            run_uuid: string;
            campaign_uuid: string;
            worker_uuid: string;
            sales_person: string;
            uprn: 0,
            outcome: string;
            outcome_at: string;
            created_by_user_uuid: string;
            updated_by_user_uuid: string;
            filename: string;
        }[], {
            run_uuid: string;
            body: {
                run_uuid: string;
                uprn: number;
                outcome: string;
                outcome_at: string;
                extra_info?: any;
                worker_uuid: string;
            };
        }>({
            query: ({ run_uuid, body }) => ({
                url: `/campaign-runs/${run_uuid}/outcomes`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
    })
});