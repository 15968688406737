import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { EmptyCardNoData } from 'elements/EmptyState';
import { Skeleton } from 'elements/Skeleton';
import { Table } from 'elements/Table';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { addRunData } from 'store/reducers/runSlice';
import styles from './styles.module.scss';

export const Main = () => {
    const { token } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { data = [], isLoading, isError, refetch } = campaignRunAPI.useGetAllCampaignRunsQuery();

    useEffect(() => {
        refetch();
    }, [token]);

    const config = [
        {
            label: 'Name',
            render: (data: { [key: string]: string; }) => data.name,
            sortValue: (data: { [key: string]: string; }) => data.name
        },

        {
            label: 'Property',
            render: (data: { [key: string]: string; }) => data.min_property_count,
            sortValue: (data: { [key: string]: string; }) => data.min_property_count
        },

        {
            label: 'Action',
            render: (data: { [key: string]: string; }) => {
                return <Button
                    size={ButtonSize.SM}
                    palette={ButtonPalette.SECONDARY_COLOR}
                    onClick={() => {
                        dispatch(addRunData({
                            name: data.name,
                            uuid: data.uuid
                        }));

                        navigate(`/${data.uuid}`);
                    }}
                >Select</Button>;
            },
        },
    ];

    if (data.length === 0 && !isLoading) return <EmptyCardNoData message="No assigned runs for you. Please contact your manager" />;
    if (isLoading) return <Skeleton.Spinner />;
    if (isError) return <h2 >Something went wrong. Try again</h2>;

    return (<div className={styles.layout}>
        <h2 className={classNames('text-lg', 'text-500', 'text-primary', styles.title)}>Assigned runs</h2>
        <Table config={config} data={data} pagination={true} />
    </div >
    );
};