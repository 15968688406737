import classNames from 'classnames';
import useSortForTable from 'hooks/useSortForTable';
import React, { useMemo, useState } from 'react';
import styles from './Table.module.scss';
import { TableBody } from './TableBody/TableBody';
import { TableFooter } from './TableFooter/TableFooter';
import { TableHeader } from './TableHeader/TableHeader';
import { getIcon } from './utils/getIcon';

interface Props {
    config: {
        label: string | number | undefined,
        render: any,
        sortValue?: any;
    }[],
    data: any;
    rowClickHandler?: any;
    selectedCount?: number;
    pagination?: boolean;
}

export const Table: React.FC<Props> = ({ config, data = [], rowClickHandler, selectedCount = 12, pagination = true }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const { sortBy, sortOrder, sortedData, handleClick } = useSortForTable(data, config);

    const updatedConfig = config.map((configItem: any) => {
        if (configItem.sortValue) {
            return {
                ...configItem,
                sortedHandler: () => {
                    handleClick(configItem.label as any);
                },
                sortedIcon: () => getIcon(configItem.label, sortBy, sortOrder)
            };
        }

        return configItem;
    });

    return (<div className={styles.tableContainer}>
        <div className={styles.tableScroll}>
            <table className={classNames(styles.table, {
                [styles.stickyColumn]: config.length > 5
            })}>
                <TableHeader config={updatedConfig} />
                <TableBody
                    selectedCount={selectedCount}
                    config={updatedConfig}
                    rowClickHandler={rowClickHandler}
                    data={
                        useMemo(() => {
                            const firstPageIndex = (currentPage - 1) * selectedCount;
                            const lastPageIndex = firstPageIndex + selectedCount;

                            return sortedData.slice(firstPageIndex, lastPageIndex);
                        }, [currentPage, selectedCount, sortedData])
                    } />

            </table>
        </div>
        {
            pagination && <TableFooter
                dataLength={data.length}
                selectedCount={selectedCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        }
    </div>);
};