import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from '../Table.module.scss';

export const TableHeader = ({ config }: any) => {
    const [columnWidths, setColumnWidths] = useState(config.map(() => 180)); // Initial width 180px
    const resizingColumnIndex = useRef(-1);
    const startXRef = useRef(0);
    const startWidthRef = useRef(0);

    const handleMouseDown = (index: number) => (e: React.MouseEvent) => {
        resizingColumnIndex.current = index;
        startXRef.current = e.clientX;
        startWidthRef.current = columnWidths[index];

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        // When the resize starts, prevent text selection.
        document.body.classList.add(styles.noSelect);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (resizingColumnIndex.current < 0) {
            return;
        }
        const currentX = e.clientX;
        const newWidths = [...columnWidths];
        newWidths[resizingColumnIndex.current] = Math.max(startWidthRef.current + currentX - startXRef.current, 50); // Minimum column width of 50px
        setColumnWidths(newWidths);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resizingColumnIndex.current = -1; // Reset the index

        // When the resize ends, allow text selection again.
        document.body.classList.remove(styles.noSelect);
    };

    const tableColumns = config.map((el: any, index: number) => {
        const isLast = index === config.length - 1;

        const handlerSort = el.sortValue ? el.sortedHandler : null;

        return (
            <th
                key={el.label}
                style={{ width: columnWidths[index] }}
                className={classNames('text-xs', 'text-500', 'text-tertiary', 'text-left', styles.th)}
                onClick={handlerSort}
            >
                {el.label}

                {handlerSort && el.sortedIcon()}
                {!isLast && <div data-testid='resizeHandle' className={styles.resizeHandle} onMouseDown={handleMouseDown(index)} />}

            </th>
        );
    });

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    return (<>
        <thead>
            <tr>{tableColumns}</tr>
        </thead>
    </>);
};