import classNames from 'classnames';
import styles from '../Table.module.scss';
import { Button } from 'components/Button';
import { ButtonPalette, ButtonSize } from 'components/Button/Button';
import { Icons } from 'icons';

export const TableFooter = ({
    dataLength,
    selectedCount,
    // setSelectedCount,
    currentPage,
    setCurrentPage
}: any) => {
    const countOfPages = Math.ceil(dataLength / selectedCount);

    const pagesList = [];

    for (let i = 1; i <= countOfPages; i++) {
        pagesList.push(
            <li key={i}
                className={classNames(styles.listItem, 'text-tertiary', 'text-sm', 'text-500', {
                    [styles.listItem__active]: i === currentPage,
                })}
                onClick={() => setCurrentPage(i)}>
                {i}
            </li>
        );
    }

    return (<div className={styles.footer}>
        <Button palette={ButtonPalette.SECONDARY_GRAY}
            size={ButtonSize.SM}
            onClick={() => {
                setCurrentPage(currentPage - 1);
            }}

            isDisabled={currentPage === 1}

        >
            <Icons.ArrowLeft />
            Previous
        </Button>

        <ul className={styles.paginationList}>
            {pagesList}
        </ul>

        <Button palette={ButtonPalette.SECONDARY_GRAY}
            size={ButtonSize.SM}
            onClick={() => {
                if (currentPage < countOfPages) {
                    setCurrentPage(currentPage + 1);
                }
            }}
            isDisabled={currentPage === countOfPages}
        >
            Next
            <Icons.ArrowRight />
        </Button>
    </div>);
};