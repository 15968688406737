import classNames from 'classnames';
import { Skeleton } from 'elements/Skeleton';
import { useEffect, useState } from 'react';
import styles from './Map.module.scss';

export const MainSkeleton = () => {

    const [inc, setInc] = useState(0);

    const arrOfP = [
        'Downloading the latest property data...',
        'Retrieving your current location to provide accurate results...',
        'Setting up the map for an optimal viewing experience...'
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setInc(prev => prev + 1);

        }, 4000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (inc === arrOfP.length - 1) {
            setInc(0);
        }
    }, [inc]);

    return <div className={classNames(styles.skeleton)}>

        <div className={styles.skeletonWrapp}>
            <Skeleton.Spinner className={styles.spinner} />

            <p className={classNames('text-sm', 'text-secondary', 'text-400')}>{arrOfP[inc]}</p>
        </div>
    </div>;
};