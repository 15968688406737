import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Logo3D from './img/infranomics_icon.png';
import style from './style.module.scss';

interface Props {
    children: any;
    imgUrl?: string;
}

export const StartFormLayout: React.FC<Props> = ({ imgUrl = '', children }) => {
    const [isMobile, setMobile] = useState(true);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);

        }
    }, [window.innerWidth]);
    return (<>
        <main className={classNames(style.layout, {
            [style.mobile]: isMobile
        })}>

            <div className={style.formBlock}>
                <div>
                    {children}
                </div>
            </div>

            {!isMobile && <div className={style.imageBlock}>
                {/* @ts-ignore */}
                <img src={!imgUrl && Logo3D} className={style.image} alt="" />
            </div>}
        </main>
    </>);
};