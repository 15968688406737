import { createSlice } from '@reduxjs/toolkit';
import { OutcomeStatus } from 'app/types/OutcomeStatus';

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        uprn: '',
        outcomeStatus: OutcomeStatus.CHURN
    },
    reducers: {
        addAddressUprn: (state, action) => {
            state.uprn = action.payload;
        },
        removeAddressUprn: (state) => {
            state.uprn = '';
        },
        addAddressOutcomeStatus: (state, action) => {
            state.outcomeStatus = action.payload.outcome;
        },
        removeAddressOutcomeStatus: (state) => {
            state.outcomeStatus = OutcomeStatus.CHURN;
        },
    }
});

export const selectAddress = (state: any) => state.address;

export const {
    addAddressUprn,
    removeAddressUprn,
    addAddressOutcomeStatus,
    removeAddressOutcomeStatus
} = addressSlice.actions;

export default addressSlice.reducer;
