import classNames from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

export enum ButtonPalette {
    PRIMARY = 'primary',
    SECONDARY_GRAY = 'secondary-gray',
    SECONDARY_COLOR = 'secondary-color',
    TERTIARY_GRAY = 'tertiary-gray',
    TERTIARY_COLOR = 'tertiary-color'
}

export enum ButtonSize {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XL2 = '2xl'
}
interface Props {
    children?: any;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    palette?: ButtonPalette,
    isDisabled?: boolean,
    isLoading?: boolean,
    className?: any;
    size?: ButtonSize,
}
export const Button: React.FC<Props> = ({
    type = 'button',
    children,
    palette = ButtonPalette.PRIMARY,
    isDisabled,
    isLoading = false,
    className,
    size = ButtonSize.MD,
    onClick = () => { }
}) => {
    const clickHandler = () => {
        onClick();
    };

    const sizeStyles = {
        [styles.button__lg]: size === ButtonSize.LG,
        [styles.button__md]: size === ButtonSize.MD,
        [styles.button__sm]: size === ButtonSize.SM,
        [styles.button__xl]: size === ButtonSize.XL,
        [styles.button__2xl]: size === ButtonSize.XL2,
    };

    const paletteStyles = {
        // [`${styles.button}__${palette}`]: palette

        [styles.button__primary]: palette === ButtonPalette.PRIMARY,
        [styles.button__secondary_gray]: palette === ButtonPalette.SECONDARY_GRAY,
        [styles.button__secondary_color]: palette === ButtonPalette.SECONDARY_COLOR,
        [styles.button__tertiary_gray]: palette === ButtonPalette.TERTIARY_GRAY,
        [styles.button__tertiary_color]: palette === ButtonPalette.TERTIARY_COLOR,
    };

    return (<button
        data-testid='Button'
        type={type}

        className={classNames(styles.button, className, {
            ...sizeStyles,
            ...paletteStyles,
            [styles.button__loading]: isLoading,
            [styles.button__disabled]: isDisabled
        })}
        onClick={clickHandler}
        disabled={isDisabled || isLoading === true}
    >
        {children}
    </button>);
};