import classNames from 'classnames';
import { Button, ButtonPalette, ButtonSize } from 'components/Button';
import { Icons } from 'icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { removeAddressOutcomeStatus, removeAddressUprn } from 'store/reducers/addressSlice';
import { removeAddresses } from 'store/reducers/campaignSlice';
import { addRunData, selectRun } from 'store/reducers/runSlice';
import { Actions } from './Actions';
import { AddressesSelector } from './AddressesSelector';
import { MainSkeleton } from './MainSkeleton';
import { Map } from './Map';
import styles from './Map.module.scss';

export const MapPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { uuid: storageUuid } = useSelector(selectRun);

    const { uuid: paramsUuid } = useParams();
    const { data: campaigns = [], isLoading } = campaignRunAPI.useGetAllCampaignRunsQuery();

    useEffect(() => {
        if (!storageUuid) {
            const item = campaigns.find((el) => el.uuid === paramsUuid);

            dispatch(addRunData({
                uuid: item?.uuid,
                name: item?.name
            }));
        }
    }, [storageUuid, isLoading]);

    const { isLoading: isPropertiesLoading, isError: isErrorProperties } = campaignRunAPI.useGetAllPropertiesInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const { isError: isErrorOutcomes } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    if (isPropertiesLoading) return <MainSkeleton />;

    return <div className={styles.pageGrid}>
        <Button size={ButtonSize.SM}
            palette={ButtonPalette.TERTIARY_COLOR}
            onClick={() => {
                navigate(-1);
                dispatch(removeAddressUprn());
                dispatch(removeAddressOutcomeStatus());
                dispatch(removeAddresses());
            }}>
            <Icons.ArrowLeft />
            Back to list of runs
        </Button>
        {(isErrorProperties || isErrorOutcomes) && <p className={classNames('text-md', 'text-500', 'text-primary', 'text-center', styles.gap)}>Problem occurred. Please try again later or contact your manager.</p>}
        {(!isErrorProperties || !isErrorOutcomes) && <div className={classNames(styles.grid)}>
            <AddressesSelector />
            <Map />
            <Actions />
        </div>}
    </div>;
};