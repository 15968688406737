import classNames from 'classnames';
import { Icons } from 'icons';
import styles from './styles.module.scss';

export const EmptyState = ({ searchValue }: { searchValue?: string; }) => {
    return <div className={styles.emptyCard}>
        <div className={styles.iconWrap}>
            <Icons.SearchLg />
        </div>

        <div className={classNames('text-center', styles.textBlock)}>
            <h3 className={classNames(
                'text-md', 'text-500', 'text-primary'
            )}>No data found</h3>
            <p className={classNames(
                'text-sm', 'text-400', 'text-tertiary', styles.subtitle
            )}>Your search “{searchValue}” did not match any data. <br />Please try again.</p>
        </div>
    </div>;
};

export const EmptyCardNoData = ({ message }: { message?: string; }) => {
    return <div className={styles.emptyCard}>
        <div className={styles.iconWrap}>
            <Icons.SearchLg />
        </div>

        <div className={classNames('text-center', styles.textBlock)}>
            <h3 className={classNames(
                'text-md', 'text-500', 'text-primary'
            )}>No data</h3>
            <p className={classNames(
                'text-sm', 'text-400', 'text-tertiary', styles.subtitle
            )}>{message}</p>
        </div>
    </div>;
};
