import classNames from 'classnames';
import { Header } from 'components/Header';
import { Outlet } from 'react-router-dom';

export const MobileLayout = () => {

    return (
        <div data-testid='App'
            className={classNames('app-layout')}
        >

            <Header />
            <main className={classNames('pageContent')}>
                <Outlet />
            </main>
        </div>
    );
};