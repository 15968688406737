import classNames from 'classnames';
import React, { useState } from 'react';
import { PasswordIcon } from './icon/PasswordIcon';
import style from './style.module.scss';

interface Props {
    type?: string;
    value?: any;
    placeholder?: string;
    error?: {
        message: string | undefined;
    };
    required?: boolean;
    onChange?: any;
    onBlur?: any;
    onInput?: any;
    onFocus?: any;
    label?: string;
    Password?: any;
    disabled?: boolean;
    className?: string;
    props?: any;
    registerProps?: any;
    min?: number;
}

enum InputTypes {
    PASSWORD = 'password',
    TEXT = 'text'
}

export const Input: React.FC<Props> & { Password: typeof Password; } = ({
    type = 'text',
    required,
    value,
    placeholder = 'Input here',
    error,
    label,
    disabled,
    onChange = () => { },
    onBlur = () => { },
    onInput = () => { },
    onFocus = () => { },
    className,
    registerProps,
    min }) => {
    return (<>
        <div className={classNames(style.inputWrapper, 'text-left', className, {
            [style.disabled]: disabled
        })}>
            {label && <label className={classNames('text-sm', 'text-500', 'text-secondary')}>{label}</label>}
            <input className={classNames(style.input, 'text-placeholder', {
                [style.inputError]: error && error.message
            })}
                type={type}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                onChange={(event: any) => onChange(event)}
                onBlur={(event: any) => onBlur(event)}
                onInput={(event: any) => onInput(event)}
                onFocus={(event: any) => onFocus(event)}
                {...registerProps}
                min={min}
            />
            {error && error.message && <span data-testid="error-message" className={classNames('text-sm', 'text-400', 'text-left', style.errorMessage)}>{error.message}</span>}
        </div>
    </>);
};

const Password: React.FC<Props> = ({
    value,
    required,
    placeholder,
    error, label,
    disabled,
    onChange = () => { },
    onBlur = () => { },
    onInput = () => { },
    onFocus = () => { },
    className,
    registerProps }) => {

    const [inputType, setInputType] = useState(InputTypes.PASSWORD);

    const handleChange = () => {
        setInputType((prev) => prev === InputTypes.PASSWORD ? InputTypes.TEXT : InputTypes.PASSWORD);
    };

    return (<div className={classNames(style.inputWrapper, className, {
        [style.disabled]: disabled
    })}>
        {label && <label className={classNames('text-sm', 'text-500', 'text-secondary')}>{label}</label>}

        <div className={classNames(style.positionWrap)}>
            <input
                data-testid="input-password"
                className={classNames(style.input, 'text-placeholder')}
                type={inputType}
                value={value}
                placeholder={placeholder}
                required={required}
                onChange={(event: any) => onChange(event)}
                onBlur={(event: any) => onBlur(event)}
                onInput={(event: any) => onInput(event)}
                onFocus={(event: any) => onFocus(event)}
                {...registerProps}
            />
            <button
                type='button'
                onClick={handleChange}
                className={style.visibilityIcon}>
                <PasswordIcon isVisible={inputType === InputTypes.TEXT} />
            </button>
        </div>

        {error && error.message && <span className={style.errorMessage}>{error.message}</span>}
    </div>);
};

Input.Password = Password;