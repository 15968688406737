import { OutcomeStatus } from 'app/types/OutcomeStatus';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Skeleton } from 'elements/Skeleton';
import { useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { selectAddress } from 'store/reducers/addressSlice';
import { selectRun } from 'store/reducers/runSlice';
import { getIndicatorByOutcome } from './AddressesSelector';

export const InfoAboutAddress = () => {
    const { uprn } = useSelector(selectAddress);
    const { uuid: storageUuid } = useSelector(selectRun);

    const { data: outcomes = [], isLoading: isOutcomesLoading } = campaignRunAPI.useGetAllOutcomeInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const item = outcomes.find((el) => el.uprn === uprn);

    const dataObj = {
        title: getIndicatorByOutcome(item?.outcome || OutcomeStatus.CHURN).title,
        time: item ? format(new Date(item?.outcome_at), 'dd.MM.yyyy') : 'No record',
    };

    return <>
        {
            isOutcomesLoading && <Skeleton height={40} />
        }
        {!isOutcomesLoading && <div>
            <h2 className={classNames('display-sm', 'text-600', 'text-primary')}>{dataObj.title}</h2 >
            <p className={classNames('text-sm', 'text-300', 'text-tertiary')}>Recorded: {dataObj.time}</p>
        </div>}
    </>;
};