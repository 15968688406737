import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { campaignAPI } from 'services/Campaigns/CampaignsService';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import { tokenAPI } from 'services/Token';
import addressSlice from './reducers/addressSlice';
import campaignSlice from './reducers/campaignSlice';
import hermesSlice from './reducers/hermesSlice';
import regionSlice from './reducers/regionSlice';
import runSlice from './reducers/runSlice';
import tokenSlice from './reducers/tokenSlice';

const rootReducer = combineReducers({
    [tokenAPI.reducerPath]: tokenAPI.reducer,
    // [userAPI.reducerPath]: userAPI.reducer,
    token: tokenSlice,
    hermes: hermesSlice,
    [usersAPI.reducerPath]: usersAPI.reducer,
    region: regionSlice,
    [campaignAPI.reducerPath]: campaignAPI.reducer,
    campaign: campaignSlice,
    [campaignRunAPI.reducerPath]: campaignRunAPI.reducer,
    run: runSlice,
    address: addressSlice
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat(
                tokenAPI.middleware,
                usersAPI.middleware,
                campaignAPI.middleware,
                campaignRunAPI.middleware,
            ),
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
