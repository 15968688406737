import { createApi } from '@reduxjs/toolkit/query/react';
import { Campaigns, CampaignsExport, CampaignsPropertiesExport } from 'models/Campaigns';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const campaignAPI = createApi({
    reducerPath: 'campaignAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        // getAllCampaigns: build.query<RegionsExport, void>({
        //     query: () => ({
        //         url: '/campaigns',
        //         method: 'GET',
        //     }),
        // }),
        createCampaign: build.mutation<CampaignsExport, Campaigns>({
            query: (body) => ({
                url: '/campaigns',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),

        createCampaignExport: build.mutation<CampaignsExport, { campaign_uuid: string; body: { n_bins: number; min_count: number; }; }>({
            query: ({ campaign_uuid, body }) => ({
                url: `campaigns/${campaign_uuid}/export`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        getSalesStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/sales?${params}`,
                method: 'GET',
            }),
        }),

        getChurnStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/churn?${params}`,
                method: 'GET',
            }),
        }),
        getDoorKnockedStats: build.query<any, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: `/campaigns/${campaign_uuid}/stats/doors-knocked?${params}`,
                method: 'GET',
            }),
        }),
        getProperties: build.query<CampaignsPropertiesExport, { campaign_uuid: string, params?: any; }>({
            query: ({ campaign_uuid, params }) => ({
                url: params ? `campaigns/${campaign_uuid}/properties?${params}` : `campaigns/${campaign_uuid}/properties`,
                method: 'GET',
            }),
        }),
    })
});