import classNames from 'classnames';
import { ROUTES } from 'constants/routes';
import { NavItem } from 'elements/NavItem';
import { Skeleton } from 'elements/Skeleton';
import { User } from 'elements/User';
import { useAuth } from 'hooks/useAuth';
import { Icons } from 'icons';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usersAPI } from 'services/Hercules/HerculesUsersService';
import styles from './Navigation.module.scss';

interface Props {
}

const Navigation: React.FC<Props> = () => {
    const { token, setToken } = useAuth();
    const { data: currentUser, isLoading: getCurrentUserIsLoading, refetch } = usersAPI.useGetCurrentUserQuery();
    const { pathname } = useLocation();

    useEffect(() => {
        refetch();
    }, [token]);

    if (!token) {
        return <Skeleton />;
    }
    return <div data-testid="navigation" className={styles.navigation}>
        <div className={styles.navigationTop}>
            {getCurrentUserIsLoading && <Skeleton />}
            {!getCurrentUserIsLoading && <User name={currentUser?.username}
                className={styles.userBadge}
                isNameShow={true}
            />}
        </div>
        <div className={styles.navigationBottom}>
            <ul className={classNames(styles.navList)}>
                <li>
                    <NavItem data-testid="nav-home"
                        link={`${pathname}${ROUTES.SETTINGS}`}>
                        <Icons.Settings />

                        Settings
                    </NavItem>
                </li>
                <hr className={styles.separator} />
                <li>
                    <div className={styles.logout} onClick={() => setToken!('')}>
                        <Icons.Logout />
                        Log out
                    </div>
                </li>
            </ul>
            {/* {getCurrentUserIsLoading && <Skeleton />}

            {currentUser?.username && <User
                name={currentUser?.username}
                email={currentUser?.email} />
            } */}
        </div>

    </div >;
};

export default Navigation;