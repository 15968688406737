import { createSlice } from '@reduxjs/toolkit';

const runSlice = createSlice({
    name: 'run',
    initialState: {
        uuid: null,
        name: '',
    },
    reducers: {
        addRunData: (state, action) => {
            state.uuid = action.payload.uuid;
            state.name = action.payload.name;
        },
        removeRunData: (state) => {
            state.uuid = null;
            state.name = '';
        },
    }
});

export const selectRun = (state: any) => state.run;

export const {
    addRunData,
    removeRunData,
} = runSlice.actions;

export default runSlice.reducer;
