import { ConfigProvider, Select as SelectAntd } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { tagRender } from './utils/TagRender';
import { selectToken } from './utils/designToken';

interface Props {
    data: { value: number | string; label: string; }[] | any;
    label?: string;
    value?: any;
    isTags?: boolean;
    onChange?: (param: number | string | any, opt?: any) => void;
    isBlock?: boolean,
    gapBottom?: boolean;
    width?: any;
    isAllowClear?: boolean;
    antdProps?: any;
    children?: any;
    isSearch?: boolean;
    placeholder?: string;
    className?: any;
}

export const Select: React.FC<Props> = ({
    children, gapBottom, data, value, label, isTags, onChange, isBlock, width, isAllowClear, antdProps,
    placeholder = 'All',
    className,
    isSearch = true
}) => {
    const [selectValue, setSelectValue] = useState(value);

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    const handleClick = (value: number, optVal?: any) => {
        if (onChange) {
            onChange(value, optVal);
        }
        setSelectValue(value);
    };

    return <div data-testid="SelectAreas" className={classNames(styles.select)}><ConfigProvider theme={selectToken}>
        <div className={classNames(styles.select, { [styles.block]: isBlock, [styles.mb16]: gapBottom })}>
            {label && <label className={classNames('text-sm', 'text-500', 'text-secondary', styles.selectLabel)}>{label}</label>}
            <SelectAntd
                showSearch={isSearch}
                options={data}
                className={classNames('text-placeholder', 'text-md', 'text-400', styles.select, className)}
                mode={isTags ? 'tags' : undefined}
                style={{ minWidth: '300px', width: '300px', ...width }}
                placeholder={placeholder}
                onChange={handleClick}
                tagRender={tagRender}
                value={selectValue ?? []}
                allowClear={isAllowClear}
                {...antdProps}
            >
                {children}
            </SelectAntd>
        </div>

    </ConfigProvider>
    </div>;
};