import { useMap } from 'app/providers/MapProvider';
import { OutcomeStatus } from 'app/types/OutcomeStatus';
import { useAuth } from 'hooks/useAuth';
import mapboxGl from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { campaignRunAPI } from 'services/Campaigns/CampaignsRunService';
import { selectAddress } from 'store/reducers/addressSlice';
import { selectRun } from 'store/reducers/runSlice';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import styles from './Map.module.scss';
import { baseLayers } from './resources/layers';
import { baseSources } from './resources/sources';

(mapboxGl as any).workerClass = MapboxWorker;

mapboxGl.accessToken = process.env.REACT_APP_MAP_TOKEN!;
const host = process.env.REACT_APP_HERMES_API_URL;

export const Map = () => {
    const { setDraw, setMap, map } = useMap();
    const { token } = useAuth();

    const { uuid: storageUuid } = useSelector(selectRun);
    const { uprn, outcomeStatus } = useSelector(selectAddress);

    const { data: properties = [] } = campaignRunAPI.useGetAllPropertiesInRunQuery({ run_uuid: storageUuid }, {
        skip: !storageUuid
    });

    const mapRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mapRef.current) return;

        const map = new mapboxGl.Map({
            container: mapRef.current,
            center: [-1.748506, 52.380998],
            transformRequest: (url: any): any => {
                if (url.startsWith(host)) {
                    return {
                        url,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            // 'cache-control': 'max-age=1, must-revalidate'
                        },
                    };
                }
            },
            // style: 'mapbox://styles/mapbox/standard',
            style: {
                version: 8,
                sources: baseSources,
                layers: baseLayers,
                glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
            },
            bounds: [-3.556744917775376, 50.459380291269525, 1.7584027519666279, 52.42691321915794],
            maxBounds: [-10.731977871337193, 49.59411301175666, 1.9010451201391163, 61.32814923895637],
            zoom: 6,
            minZoom: 5.5 // zoom < 5.5 caused 404s. 
        });

        const geolocate = new mapboxGl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });

        // Add the control to the map.
        map.addControl(geolocate);

        map.on('load', async () => {
            setMap(map);
            geolocate.trigger();

        });

        return () => {
            setDraw(null);
            setMap(null);
        };
    }, []);

    useEffect(() => {
        if (!map) return;
        if (!storageUuid) return;

        if (map.getSource('prop')) {
            return;
        }

        map.addSource('prop', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaign-runs/${storageUuid}/properties/tiles/{z}/{x}/{y}.mvt`,
            ],
        });
        map.addLayer({
            id: 'prop-circle-layer',
            type: 'circle',
            source: 'prop',
            'source-layer': 'default',
            paint: {
                'circle-color': '#000000',
                'circle-opacity': 0.6,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [2, 4],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 1,
                'circle-stroke-color': 'black'
            },
        });

    }, [map, storageUuid]);

    useEffect(() => {
        if (!map) return;
        if (!outcomeStatus) return;

        if (map.getSource('prop-outcome')) {
            map.removeLayer('prop-outcome-circle-layer');
            map.removeSource('prop-outcome');
        }

        map.addSource('prop-outcome', {
            type: 'vector',
            tiles: [
                `${host}/api/v1/campaign-runs/${storageUuid}/outcomes/properties/tiles/{z}/{x}/{y}.mvt`,
            ],
        });

        map.addLayer({
            id: 'prop-outcome-circle-layer',
            type: 'circle',
            source: 'prop-outcome',
            'source-layer': 'default',
            paint: {
                'circle-color': [
                    'match',
                    ['get', 'outcome'],
                    OutcomeStatus.NO_SALE,
                    '#FF0000',
                    OutcomeStatus.NO_SALE.toLowerCase(),
                    '#FF0000',
                    OutcomeStatus.NO_ANSWER,
                    '#FFD700',
                    OutcomeStatus.NO_ANSWER.toLowerCase(),
                    '#FFD700',
                    OutcomeStatus.SALE,
                    '#2E8B57',
                    OutcomeStatus.SALE.toLowerCase(),
                    '#2E8B57',
                    '#000000'
                ],
                'circle-opacity': 1,
                'circle-radius': {
                    'base': 1.75,
                    'stops': [
                        [2, 4],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 1,
                // 'circle-stroke-color': 'black'
            },
        });

    }, [map, outcomeStatus, storageUuid]);

    useEffect(() => {
        if (!map) return;
        if (!uprn) return;

        if (map.getLayer('selected-uprn-layer')) {
            map.removeLayer('selected-uprn-layer');
        }

        map.addLayer({
            id: 'selected-uprn-layer',
            type: 'circle',
            source: 'prop',
            'source-layer': 'default',
            paint: {
                'circle-color': [
                    'match',
                    ['get', 'uprn'],
                    uprn,
                    '#66CCFF',

                    'transparent'
                ],
                'circle-opacity': 1,
                'circle-radius': {
                    'base': 1.6,
                    'stops': [
                        [2, 5],
                        [22, 90]
                    ]
                },
                'circle-stroke-width': 1,
                'circle-stroke-color': [
                    'match',
                    ['get', 'uprn'],
                    uprn,
                    'black',

                    'transparent'
                ]
            },
        });

    }, [map, uprn]);

    useEffect(() => {
        if (!map) return;
        if (!uprn) return;
        if (properties.length === 0) return;

        const coords = properties.find((el: any) => el.uprn === uprn);

        if (coords && coords.geometry.coordinates) {
            map.flyTo({
                center: coords.geometry.coordinates,
                zoom: 17
            });
        }
    }, [properties, map, uprn]);

    useEffect(() => {
        if (!map) return;
        if (properties.length === 0) return;

        map.on('mouseenter', 'prop-circle-layer', () => {
            map.getCanvas().style.cursor = 'pointer';
        });
        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'prop-circle-layer', () => {
            map.getCanvas().style.cursor = '';
        });
        map.on('click', 'prop-circle-layer', (e) => {
            // @ts-ignore
            const item = properties.find((el) => el.uprn === e.features[0].properties.uprn);

            if (item) {
                const address = `${item.road} ${item.building_number || item.building_name}, ${item.postcode}`;
                new mapboxGl.Popup({ closeButton: false })
                    .setLngLat([e.lngLat.lng, e.lngLat.lat])
                    .setHTML(`<h1>${address}</h1>`)
                    .addTo(map);

            }

            map.flyTo({
                center: [e.lngLat.lng, e.lngLat.lat],
                zoom: 17
            });
        });

    }, [map, properties]);

    return (
        <>
            <div className={styles.mapContainer} data-testid='Map'>
                <div className={styles.map} id='map' ref={mapRef} />
            </div>
        </>
    );
};