import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateUser, CreateUserResponse, DeleteUser, DeleteUserResponse, UpdateUser, UserExport, UsersExport } from 'models/HercUsers';
import { createBaseQueryWithAuth } from '../createBaseQueryWithAuth';

export const usersAPI = createApi({
    reducerPath: 'analyticsAPI',
    baseQuery: createBaseQueryWithAuth(`${process.env.REACT_APP_HERMES_API_URL}/api/v1`),
    tagTypes: ['Create', 'Delete', 'Update'],
    endpoints: (build) => ({
        getCurrentUser: build.query<UserExport, void>({
            query: () => ({
                url: '/users/current-user',
                method: 'GET',
            }),
        }),
        getAllUsers: build.query<UsersExport, void>({
            query: () => ({
                url: '/users?embed=regions',
                method: 'GET',
            }),
        }),
        createUser: build.mutation<CreateUserResponse, CreateUser>({
            query: (body) => ({
                url: '/users',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Create']
        }),
        updateUser: build.mutation<CreateUserResponse, UpdateUser>({
            query: ({ user_uuid, body }) => ({
                url: `/users/${user_uuid}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: ['Update']
        }),
        deleteUser: build.mutation<DeleteUserResponse, DeleteUser>({
            query: (user_uuid) => ({
                url: `/users/${user_uuid}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Delete']
        }),
        addRegionToUser: build.mutation<string[], { user_uuid: string, body: any; }>({
            query: ({ user_uuid, body }) => ({
                url: `/users/${user_uuid}/add-regions`,
                method: 'POST',
                body
            }),
        }),
        deleteRegionFromUser: build.mutation<string[], { user_uuid: string, body: any; }>({
            query: ({ user_uuid, body }) => ({
                url: `/users/${user_uuid}/delete-regions`,
                method: 'POST',
                body
            }),
        }),
    })
});