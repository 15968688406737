export enum OutcomeStatus {
    NO_SALE = 'NO_SALE',
    NO_ANSWER = 'NO_ANSWER',
    SALE = 'SALE',
    CHURN = 'CHURN',
    NOT_RIGHT_PERSON = 'NO_RIGHT_PERSON',
    REQUIRES_VOIP = 'REQUIRES_VOIP',
    TOO_EXPENSIVE = 'TOO_EXPENSIVE',
    BAD_REVIEWS = 'BAD_REVIEWS',
    TOO_FAST = 'TOO_FAST',
    VULNERABLE = 'VULNERABLE',
    DO_NOT_KNOCK = 'DO_NOT_KNOCK',
    FUTURE_LEAD = 'FUTURE_LEAD',
}