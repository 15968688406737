import classNames from 'classnames';
import styles from './style.module.scss';

export const Skeleton = ({ notFlickering = false, height = 50 }: any) => {
    return <div className={classNames(styles.skeleton, {
        [styles.notFlickering]: notFlickering
    })}
        style={{
            height
        }}></div>;
};

const TableEl = () => {
    return <div className={classNames(styles.skeleton, styles.skeletonS)}></div>;
};

Skeleton.TableCell = TableEl;

const Row = ({ className }: any) => {
    return <div className={classNames(styles.skeleton, className)}></div>;
};

Skeleton.Row = Row;

const ChartSkeleton = ({ notFlickering = false }: any) => {
    return (<div className={classNames(styles.chartSkeleton)}>
        <div className={classNames(styles.skeleton, styles.chart, {
            [styles.notFlickering]: notFlickering
        })}></div>
    </div>);
};

const ChartPlaceholder = () => {
    return <div className={classNames(styles.placeholder)}></div>;

};

Skeleton.ChartSkeleton = ChartSkeleton;
Skeleton.ChartPlaceholder = ChartPlaceholder;

export const Spinner = ({ className }: any) => {
    return <div className={classNames(styles.parent, className)}>
        <div className={styles.ldsRoller}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>;
};
Skeleton.Spinner = Spinner;